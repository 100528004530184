import Banner from '../components/reusables/banner'
import Centered from '../components/reusables/centered'
import HomeHero from '../components/reusables/homeHero'
import ImageText from '../components/reusables/imageText'
import Layout from '../components/layout'
import React from 'react'
import Timeline from '../components/reusables/timeline'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

const IndexPage = ({ data }) => (
  <Layout>
    <HomeHero data={data} />
    <ImageText index="0" data={data} shade={'light'} />
    <ImageText index="1" data={data} shade={'grey'} />
    <ImageText index="2" data={data} shade={'light'} />
    <Timeline data={data} shade={'light'} />
    <Centered index="0" data={data} shade={'grey'} />
  </Layout>
)

export default withIntl(IndexPage)

export const imagesQuery = graphql`
  query {
    cybermilesHeader: file(relativePath: { eq: "cybermilesHeader@3x.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    lastSection: file(relativePath: { eq: "hero/lastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    play: file(relativePath: { eq: "icons/play@3x.png" }) {
      ...buttonIcon
    }
  }
`
