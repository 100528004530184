import 'lightbox-react/style.css'

import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import { StyledH2 } from './styles'
import animation from './gifs/home.mp4'
import { detect } from 'detect-browser'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const browser = detect()

const StyledContainer = styled.div`
  width: 100%;
  height: 110vh;
  position: relative;
`

const StyledVideoContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  #myVideo {
    height: 100vh;
    width: 100%;
    object-fit: cover;

    ${media.greaterThan('medium')`
      width: 100%;
      height: 100%;
    `}
  }
`
const StyledBox = styled(Box)`
  color: ${props => props.theme.colors.primaryDark};
`

const StyledH1 = styled.h1`
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 56px;
`

const StyledButtonContainer = styled(Box)`
  max-width: 400px;
`
const StyledImageContainer = styled(Box)`
  width: 272px;
  height: 65px;
`

const LightBoxContainer = styled(Box)`
  div {
    z-index: 99;
  }
`
class HomeHero extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { intl, data } = this.props
    const { isOpen } = this.state
    const title = intl.formatMessage({
      id: `banner.title`,
    })
    const hasTitle = title !== 'null'
    const description = intl.formatMessage({
      id: `banner.description`,
    })
    const hasDescription = description !== 'null'
    const buttons = intl.formatMessage({
      id: `banner.buttons.number`,
    })
    return (
      <StyledContainer>
        <StyledVideoContainer>
          <video playsinline autoPlay muted loop id="myVideo">
            {browser.name === 'safari' ? (
              <source src="https://prismic-io.s3.amazonaws.com/cybermiles%2F2abd4110-9dd0-43bb-93d9-26866cfc470f_home.mp4" type="video/mp4" />
            ) : (
              <source src={animation} type="video/mp4" />
            )}
          </video>
        </StyledVideoContainer>
        <StyledBox
          align="start"
          justify="center"
          height="100%"
          pad={{ horizontal: 'xlarge', vertical: 'large' }}
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <StyledImageContainer>
            <Img
              alt="CyberMiles"
              sizes={data.cybermilesHeader.childImageSharp.fluid}
            />
          </StyledImageContainer>
          {hasDescription && (
            <StyledH2 style={{ margin: '32px 0' }}>{description}</StyledH2>
          )}
          <StyledButtonContainer>
            {Array.from(Array(Number(buttons)).keys()).map((i, index) => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `banner.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `banner.buttons.${button}.link`,
              })
              const buttonIcon = intl.formatMessage({
                id: `banner.buttons.${button}.icon`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  onClick={() => this.setState({ isOpen: true })}
                  light={true}
                  icon={buttonIcon}
                  data={data}
                />
              )
            })}
          </StyledButtonContainer>
        </StyledBox>
        {isOpen && (
          <LightBoxContainer>
            <ReactImageVideoLightbox
              data={[
                {
                  url: 'https://www.youtube.com/embed/o3SE_DV9iTs',
                  type: 'video',
                  altTag: 'about cybermiles',
                },
              ]}
              startIndex={0}
              showResourceCount={true}
              onCloseCallback={() => this.setState({ isOpen: false })}
            />
          </LightBoxContainer>
        )}
      </StyledContainer>
    )
  }
}

HomeHero.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.object,
}

export default injectIntl(HomeHero)
